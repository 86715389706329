<template>
	<div class="container">
		<button ref="myButton" v-auth="auth" :disabled="buttonFlag" @click="handleClickLevel" class="level-btn" :class="size">
      {{ btnTitle }}
    </button>
		<lissModel v-model="visible" :auth="auth" @confirm="handleClickConfirm"></lissModel>
	</div>
</template>
  
<script>
import lissModel from "@/components/lissModel/index";

export default {
  name: "LevelButton",
	components: { lissModel },
  data() {
    return {
			visible: false
		}
  },
  props: {
		level: {
      type: Number,
      default: 0
    },
		size: {
			type: String,
			default: 'small' // middle bigger
		},
		btnTitle: {
			type: String,
      default: ''
		},
		hasVerify: {
			type: Boolean,
      default: false
		},
		auth: {
      type: String,
      default: ''
    },
    buttonFlag:{
			type: Boolean,
      default: false
		},
  },
  methods: {
		handleClickLevel () {
			const button = this.$refs.myButton
      const attributeValue = button.getAttribute('data-level')
			// 调用等级验证弹窗
			if ([2, 3].includes(+attributeValue)) {
				if (this.hasVerify) {
					this.$emit('verify', (bool) => {
						if (bool) {
							this.$emit('close')
						}
						this.visible = bool
					})
				} else {
          this.$emit('close')
					this.visible = true
				}
			} else {
				if (this.hasVerify) {
					this.$emit('verify', (bool) => {
						if (bool) {
							this.$emit('close')
							this.$emit('confirm')
						}
					})
				} else {
					this.$emit('confirm');
				}
			}
		},
		handleClickConfirm () {
			this.$emit('confirm')
		}
  }
}
</script>
  
<style scoped>
.container {
	display: inline-block;
}
.level-btn {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: var(--themeColor);
  position: relative;
  padding: 0 11px;
  cursor: pointer;
  cursor: pointer;
  background: transparent;
  border: none;
  position: relative;
}
.bigger {
	display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #FFF;
  border: 1px solid #DCDFE6;
  color: #606266;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  font-weight: 500;
  height: 36px;
  font-size: 14px;
  border-radius: 4px;
}
.middle {
	width: 60px;
  height: 36px;
	color: #fff;
	background-color: var(--themeColor);
	display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  border-radius: 4px;
	margin-left: 8px;
	padding: 0;
}
.medium{
  width: 100px;
  background: var(--themeColor);
  border-color: var(--themeColor);
  width: 120px;
  height: 36px;
  padding: 0;
  color: #ffffff;
  line-height: 36px;
  border-radius: 4px;
}
.RadioCSS{
  width: 100px;
  background: var(--themeColor);
  border-color: var(--themeColor);
  width: 90px;
  height: 36px;
  padding: 0;
  color: #ffffff;
  line-height: 36px;
  border-radius: 5px;
}

</style>
  